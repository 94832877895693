
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexaVGShqVlUCMeta } from "/home/dashboard/core:client/pages/index.vue?macro=true";
import { default as _4045YHYhVegbCMeta } from "/home/dashboard/core:client/pages/404.vue?macro=true";
import { default as forgot_45password2da0yQ1Yl1Meta } from "/home/dashboard/core:client/pages/forgot-password.vue?macro=true";
import { default as loginL9AVgqkrL7Meta } from "/home/dashboard/core:client/pages/login.vue?macro=true";
import { default as logoutNtifAcItgIMeta } from "/home/dashboard/core:client/pages/logout.vue?macro=true";
import { default as indexs2xSGPeRkvMeta } from "/home/dashboard/core:client/pages/register/index.vue?macro=true";
import { default as indexASTCH5QNQFMeta } from "/home/dashboard/core:client/pages/reset-password/index.vue?macro=true";
import { default as step_452myL4cQOsHqMeta } from "/home/dashboard/core:client/pages/register/step-2.vue?macro=true";
import { default as _91code_930nvkQBS2Q3Meta } from "/home/dashboard/core:client/pages/reset-password/[code].vue?macro=true";
import { default as indexXt5hkJu0DGMeta } from "/home/dashboard/core:template/pages/index.vue?macro=true";
import { default as _404hivj3DLpWQMeta } from "/home/dashboard/core:template/pages/404.vue?macro=true";
import { default as forgot_45passwordiA1IyMf6K2Meta } from "/home/dashboard/core:template/pages/forgot-password.vue?macro=true";
import { default as loginwOlHJoSPnQMeta } from "/home/dashboard/core:template/pages/login.vue?macro=true";
import { default as indexMLzrR71ZNmMeta } from "/home/dashboard/core:template/pages/register/index.vue?macro=true";
import { default as step_452ybzyyJXFbpMeta } from "/home/dashboard/core:template/pages/register/step-2.vue?macro=true";
import { default as indexoZo0n8hcapMeta } from "/home/dashboard/core:template/pages/auth/reset-password/index.vue?macro=true";
import { default as _91code_93pZqpxDupMWMeta } from "/home/dashboard/core:template/pages/auth/reset-password/[code].vue?macro=true";
export default [
  {
    name: "Core:client-index",
    path: "/",
    meta: { ...(indexaVGShqVlUCMeta || {}), ...{"app":"core:client"} },
    component: () => import("/home/dashboard/core:client/pages/index.vue")
  },
  {
    name: "Core:client-404",
    path: "//404",
    meta: {"app":"core:client"},
    component: () => import("/home/dashboard/core:client/pages/404.vue")
  },
  {
    name: "Core:client-forgot-password",
    path: "//forgot-password",
    meta: { ...(forgot_45password2da0yQ1Yl1Meta || {}), ...{"app":"core:client"} },
    component: () => import("/home/dashboard/core:client/pages/forgot-password.vue")
  },
  {
    name: "Core:client-login",
    path: "//login",
    meta: { ...(loginL9AVgqkrL7Meta || {}), ...{"app":"core:client"} },
    component: () => import("/home/dashboard/core:client/pages/login.vue")
  },
  {
    name: "Core:client-logout",
    path: "//logout",
    meta: { ...(logoutNtifAcItgIMeta || {}), ...{"app":"core:client"} },
    component: () => import("/home/dashboard/core:client/pages/logout.vue")
  },
  {
    name: "Core:client-register",
    path: "//register",
    meta: { ...(indexs2xSGPeRkvMeta || {}), ...{"app":"core:client"} },
    component: () => import("/home/dashboard/core:client/pages/register/index.vue")
  },
  {
    name: "Core:client-reset-password",
    path: "//reset-password",
    meta: { ...(indexASTCH5QNQFMeta || {}), ...{"app":"core:client"} },
    component: () => import("/home/dashboard/core:client/pages/reset-password/index.vue")
  },
  {
    name: "Core:client-register-step-2",
    path: "//register/step-2",
    meta: { ...(step_452myL4cQOsHqMeta || {}), ...{"app":"core:client"} },
    component: () => import("/home/dashboard/core:client/pages/register/step-2.vue")
  },
  {
    name: "Core:client-reset-password-code",
    path: "//reset-password/:code",
    meta: { ...(_91code_930nvkQBS2Q3Meta || {}), ...{"app":"core:client"} },
    component: () => import("/home/dashboard/core:client/pages/reset-password/[code].vue")
  },
  {
    name: "Core:template-index",
    path: "/templates",
    meta: { ...(indexXt5hkJu0DGMeta || {}), ...{"app":"core:template"} },
    component: () => import("/home/dashboard/core:template/pages/index.vue")
  },
  {
    name: "Core:template-404",
    path: "/templates/404",
    meta: {"app":"core:template"},
    component: () => import("/home/dashboard/core:template/pages/404.vue")
  },
  {
    name: "Core:template-forgot-password",
    path: "/templates/forgot-password",
    meta: { ...(forgot_45passwordiA1IyMf6K2Meta || {}), ...{"app":"core:template"} },
    component: () => import("/home/dashboard/core:template/pages/forgot-password.vue")
  },
  {
    name: "Core:template-login",
    path: "/templates/login",
    meta: { ...(loginwOlHJoSPnQMeta || {}), ...{"app":"core:template"} },
    component: () => import("/home/dashboard/core:template/pages/login.vue")
  },
  {
    name: "Core:template-register",
    path: "/templates/register",
    meta: { ...(indexMLzrR71ZNmMeta || {}), ...{"app":"core:template"} },
    component: () => import("/home/dashboard/core:template/pages/register/index.vue")
  },
  {
    name: "Core:template-register-step-2",
    path: "/templates/register/step-2",
    meta: { ...(step_452ybzyyJXFbpMeta || {}), ...{"app":"core:template"} },
    component: () => import("/home/dashboard/core:template/pages/register/step-2.vue")
  },
  {
    name: "Core:template-auth-reset-password",
    path: "/templates/auth/reset-password",
    meta: { ...(indexoZo0n8hcapMeta || {}), ...{"app":"core:template"} },
    component: () => import("/home/dashboard/core:template/pages/auth/reset-password/index.vue")
  },
  {
    name: "Core:template-auth-reset-password-code",
    path: "/templates/auth/reset-password/:code",
    meta: { ...(_91code_93pZqpxDupMWMeta || {}), ...{"app":"core:template"} },
    component: () => import("/home/dashboard/core:template/pages/auth/reset-password/[code].vue")
  }
]