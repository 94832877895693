import 'leaflet/dist/leaflet.css';
import * as L from 'leaflet';

export default defineNuxtPlugin(() => {
    if (process.client) {
        // Load leaflet-heatmap only in the browser
        const script = document.createElement('script');
        script.src = 'https://unpkg.com/leaflet.heat@0.2.0/dist/leaflet-heat.js';
        document.head.appendChild(script);
    }

    return {
        provide: {
            L
        }
    }

});