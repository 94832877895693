import payload_plugin_qLmFnukI99 from "/home/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/home/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_5OBXNDWXw8 from "/home/dashboard/.build/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideover_ZLoaDmlvnw from "/home/node_modules/@nuxt/ui/dist/runtime/plugins/slideover.js";
import modal_X5eKYYwod7 from "/home/node_modules/@nuxt/ui/dist/runtime/plugins/modal.js";
import colors_ETtiVmZNmO from "/home/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/home/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/home/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import pusher_client_CJB2UGofUN from "/home/dashboard/core/plugins/pusher.client.ts";
import qr_client_PYT9FZqN6K from "/home/dashboard/core/plugins/qr.client.ts";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_5OBXNDWXw8,
  prefetch_client_5tzzN0oIVL,
  slideover_ZLoaDmlvnw,
  modal_X5eKYYwod7,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  pusher_client_CJB2UGofUN,
  qr_client_PYT9FZqN6K
]