<template lang="pug">
UDropdown(
    :items="items"
    :ui="{ item: { disabled: 'cursor-text select-text' } }"
)
    .flex.gap-1.items-center
        .flex
            UIcon(
                name="i-ic-sharp-account-circle"
                class="text-neutral-500 dark:text-neutral-400 w-6 h-6"
            )
        .flex.self-center.hidden(class="sm:block")
            .flex.flex-1
                .flex.flex-col.space-y-0.gap-0.self-center.text-sm.truncate.text-neutral-700(class="dark:text-neutral-300")
                    .flex {{store?.meValue?.fullname}}
                    .flex
                        span.text-xs.bg-blue-100.px-1.py-0.rounded-md(class="dark:bg-neutral-700") {{store?.meValue?.role}}

    //- slot account
    template(#account="{ item }")
        .flex.flex-1.flex-col.justify-start.gap-2
            .flex-1.justify-start
                .label.text-left.text-xs Signed in as
            .flex.flex-1.flex-col.text-left.gap-1
                .truncate.font-medium.text-gray-900(class="dark:text-white")
                    | {{store?.meValue?.email}}
                .truncate.font-medium.text-gray-900(
                    v-if="store?.meValue?.vendor && store?.meValue?.vendor?.vendor_code"
                    class="dark:text-white"
                )

    //- slot colorway
    template(#colorway="{ item }")
        .cursor-pointer.flex.flex-1
            .flex.flex-1.capitalize(@click="isDark = !isDark")
                span(v-if="!isDark") Dark
                span(v-else) Light
            .flex
                ClientOnly
                    UIcon(
                        class="p-0 m-0 text-gray-400 w-4 h-4 dark:text-gray-500"
                        :name="isDark ? 'i-heroicons-sun-20-solid' : 'i-mdi-weather-night'"
                        @click="isDark = !isDark"
                    )
    template(#item="{ item }")
        NuxtLink.flex.flex-1(
            v-if="item.link"
            :to="item.link"
        )
            .truncate {{ item.label }}
        .truncate(v-else) {{ item.label }}
        UIcon(
            v-if="item.icon"
            :name="item.icon"
            class="flex-shrink-0 h-4 w-4 text-gray-400 dark:text-gray-500 ms-auto")

    template(#fallback)
</template>
<script setup lang="ts">
const store = UseAuthAdminStore()
const items = [
    [
        {
            label: 'x',
            slot: 'account',
            disabled: true,
        },
    ],
    [
        {
            link: '/admin/profile',
            label: 'Profile',
            icon: 'i-material-symbols-manage-accounts',
        },
        {
            label: 'Dad',
            slot: 'colorway',
        },
    ],
    [
        {
            label: 'Sign out',
            link: '/admin/logout',
            icon: 'i-heroicons-arrow-left-on-rectangle',
        },
    ],
]
const colorMode = useColorMode()
const isDark = computed({
    get() {
        return colorMode.value === 'dark'
    },
    set() {
        colorMode.preference = colorMode.value === 'dark' ? 'light' : 'dark'
    },
})
</script>
