
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as create_45orderuyAahyngGLMeta } from "/home/dashboard/app:client/pages/101red/dropship/create-order.vue?macro=true";
import { default as kebijakan_45dan_45privasiR1HO9NSDf2Meta } from "/home/dashboard/app:client/pages/101red/p/kebijakan-dan-privasi.vue?macro=true";
import { default as syarat_45dan_45kondisivMMXWmmjXYMeta } from "/home/dashboard/app:client/pages/101red/p/syarat-dan-kondisi.vue?macro=true";
import { default as _91id_93qhguw7ta7UMeta } from "/home/dashboard/app:client/pages/101red/x/[id].vue?macro=true";
import { default as indexiRcwmpvFq5Meta } from "/home/dashboard/app:client/pages/101red/x/index.vue?macro=true";
import { default as forgot_45passwordECRiGDOas3Meta } from "/home/dashboard/app:client/pages/forgot-password.vue?macro=true";
import { default as indexQgTOdgL3QMMeta } from "/home/dashboard/app:client/pages/index.vue?macro=true";
import { default as loginCr8Nd2WSp8Meta } from "/home/dashboard/app:client/pages/login.vue?macro=true";
import { default as logoutOdzZDIn3wSMeta } from "/home/dashboard/app:client/pages/logout.vue?macro=true";
import { default as edityo7jSN997HMeta } from "/home/dashboard/app:client/pages/profile/edit.vue?macro=true";
import { default as indexTx5EUOXGLkMeta } from "/home/dashboard/app:client/pages/profile/index.vue?macro=true";
import { default as vacationlHmMdpQG6FMeta } from "/home/dashboard/app:client/pages/profile/vacation.vue?macro=true";
import { default as wishlistTUqcOjXEv2Meta } from "/home/dashboard/app:client/pages/profile/wishlist.vue?macro=true";
import { default as registerA91WXhwh71Meta } from "/home/dashboard/app:client/pages/register.vue?macro=true";
import { default as _91code_93zVzhqm3qXsMeta } from "/home/dashboard/app:client/pages/reset-password/[code].vue?macro=true";
import { default as indexLg0qDemAe1Meta } from "/home/dashboard/app:client/pages/reset-password/index.vue?macro=true";
import { default as indexsgiz5KSf3FMeta } from "/home/dashboard/app:admin/pages/xadmin/index.vue?macro=true";
import { default as index1de6ZtaWs0Meta } from "/home/modules/app_inventory/views/pages/index.vue?macro=true";
import { default as index9PeNoekkxEMeta } from "/home/modules/app_inventory/views/pages/brands/index.vue?macro=true";
import { default as indexgdjpPvXCg8Meta } from "/home/modules/app_inventory/views/pages/categories/index.vue?macro=true";
import { default as indexI6ZeQusNf4Meta } from "/home/modules/app_inventory/views/pages/courier/index.vue?macro=true";
import { default as indexORVEeyKCbSMeta } from "/home/modules/app_inventory/views/pages/export/index.vue?macro=true";
import { default as indexZXzeo5Ce8lMeta } from "/home/modules/app_inventory/views/pages/import/index.vue?macro=true";
import { default as index8HSjOqF7rRMeta } from "/home/modules/app_inventory/views/pages/products/index.vue?macro=true";
import { default as indextGqt2yeEvIMeta } from "/home/modules/app_inventory/views/pages/stocks/index.vue?macro=true";
import { default as indexMGr7mMc4f0Meta } from "/home/modules/app_inventory/views/pages/warehouse/index.vue?macro=true";
import { default as opnameaKYYyz735DMeta } from "/home/modules/app_inventory/views/pages/stocks/opname.vue?macro=true";
import { default as _91id_93N3TznSNJUBMeta } from "/home/modules/app_inventory/views/pages/courier/[id].vue?macro=true";
import { default as _91id_93E3HIhROWQxMeta } from "/home/modules/app_inventory/views/pages/products/[id].vue?macro=true";
import { default as _91id_93yJGHzrRPs1Meta } from "/home/modules/app_inventory/views/pages/warehouse/[id].vue?macro=true";
import { default as indexJaGsZ5T0vqMeta } from "/home/modules/app_base/views/pages/index.vue?macro=true";
import { default as new_45orderp6F2nLMc4NMeta } from "/home/modules/app_base/views/pages/new-order.vue?macro=true";
import { default as indexKsrLHtOQCcMeta } from "/home/modules/app_base/views/pages/orders/index.vue?macro=true";
import { default as index5AgPODb0ruMeta } from "/home/modules/app_base/views/pages/products/index.vue?macro=true";
import { default as indexTC44NtdQ26Meta } from "/home/modules/app_base/views/pages/toko/index.vue?macro=true";
import { default as indexrsDiXOlSaSMeta } from "/home/modules/app_salesorder/views/pages/index.vue?macro=true";
import { default as indexnfBH8SEeCCMeta } from "/home/modules/app_admin/views/pages/index.vue?macro=true";
import { default as forgot_45password8OHDiWyndFMeta } from "/home/modules/app_admin/views/pages/forgot-password.vue?macro=true";
import { default as loginEwuG0DtJmOMeta } from "/home/modules/app_admin/views/pages/login.vue?macro=true";
import { default as logoutOfOCnJALDpMeta } from "/home/modules/app_admin/views/pages/logout.vue?macro=true";
import { default as registeryUuapWuC6IMeta } from "/home/modules/app_admin/views/pages/register.vue?macro=true";
import { default as indexLsUTFpyoGsMeta } from "/home/modules/app_admin/views/pages/administrator/index.vue?macro=true";
import { default as indexSEFdrVm3abMeta } from "/home/modules/app_admin/views/pages/privileges/index.vue?macro=true";
import { default as indexe5PAMPV2Z7Meta } from "/home/modules/app_admin/views/pages/profile/index.vue?macro=true";
import { default as indexm4oqvJZm9pMeta } from "/home/modules/app_admin/views/pages/transaksi/index.vue?macro=true";
import { default as indexyiE1mRdCJ8Meta } from "/home/modules/app_admin/views/pages/users/index.vue?macro=true";
import { default as indexftw5ez7SVGMeta } from "/home/modules/app_admin/views/pages/administrator/logs/index.vue?macro=true";
import { default as indexs6fAzG50uPMeta } from "/home/modules/app_admin/views/pages/administrator/privileges/index.vue?macro=true";
import { default as indexBzbH6UgcAYMeta } from "/home/modules/app_admin/views/pages/administrator/services/index.vue?macro=true";
import { default as indexW6zKQ9SHY9Meta } from "/home/modules/app_admin/views/pages/administrator/users/index.vue?macro=true";
import { default as indexnbf5aowkuuMeta } from "/home/modules/app_admin/views/pages/users/activities/index.vue?macro=true";
import { default as indexJuZ4JjLORYMeta } from "/home/modules/app_admin/views/pages/users/exports/index.vue?macro=true";
import { default as indexmxwAzsl1B5Meta } from "/home/modules/app_admin/views/pages/users/notifications/index.vue?macro=true";
import { default as indexSebUtoQ4l5Meta } from "/home/modules/app_admin/views/pages/users/roles/index.vue?macro=true";
import { default as indexJIyDsDFcSeMeta } from "/home/modules/app_admin/views/pages/administrator/users/activities/index.vue?macro=true";
import { default as indexLXWCifcqY5Meta } from "/home/modules/app_admin/views/pages/administrator/users/detail/index.vue?macro=true";
import { default as indexHM3WG9qKkaMeta } from "/home/modules/app_admin/views/pages/administrator/users/exports/index.vue?macro=true";
import { default as indexde2mPLBimZMeta } from "/home/modules/app_admin/views/pages/administrator/users/notifications/index.vue?macro=true";
import { default as indexQm22bj0DYeMeta } from "/home/modules/app_admin/views/pages/administrator/users/roles/index.vue?macro=true";
import { default as _91id_93o1OpL6mBvVMeta } from "/home/modules/app_admin/views/pages/administrator/users/detail/[id].vue?macro=true";
import { default as index4tLTaKUtPBMeta } from "/home/modules/app_users/views/pages/index.vue?macro=true";
import { default as indexSoDJaSXOifMeta } from "/home/modules/app_assets/views/pages/index.vue?macro=true";
export default [
  {
    name: "101red-dropship-create-order",
    path: "/101red/dropship/create-order",
    meta: create_45orderuyAahyngGLMeta || {},
    component: () => import("/home/dashboard/app:client/pages/101red/dropship/create-order.vue")
  },
  {
    name: "101red-p-kebijakan-dan-privasi",
    path: "/101red/p/kebijakan-dan-privasi",
    meta: kebijakan_45dan_45privasiR1HO9NSDf2Meta || {},
    component: () => import("/home/dashboard/app:client/pages/101red/p/kebijakan-dan-privasi.vue")
  },
  {
    name: "101red-p-syarat-dan-kondisi",
    path: "/101red/p/syarat-dan-kondisi",
    meta: syarat_45dan_45kondisivMMXWmmjXYMeta || {},
    component: () => import("/home/dashboard/app:client/pages/101red/p/syarat-dan-kondisi.vue")
  },
  {
    name: "101red-x-id",
    path: "/101red/x/:id()",
    meta: _91id_93qhguw7ta7UMeta || {},
    component: () => import("/home/dashboard/app:client/pages/101red/x/[id].vue")
  },
  {
    name: "101red-x",
    path: "/101red/x",
    meta: indexiRcwmpvFq5Meta || {},
    component: () => import("/home/dashboard/app:client/pages/101red/x/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordECRiGDOas3Meta || {},
    component: () => import("/home/dashboard/app:client/pages/forgot-password.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexQgTOdgL3QMMeta || {},
    component: () => import("/home/dashboard/app:client/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginCr8Nd2WSp8Meta || {},
    component: () => import("/home/dashboard/app:client/pages/login.vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: logoutOdzZDIn3wSMeta || {},
    component: () => import("/home/dashboard/app:client/pages/logout.vue")
  },
  {
    name: "profile-edit",
    path: "/profile/edit",
    meta: edityo7jSN997HMeta || {},
    component: () => import("/home/dashboard/app:client/pages/profile/edit.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexTx5EUOXGLkMeta || {},
    component: () => import("/home/dashboard/app:client/pages/profile/index.vue")
  },
  {
    name: "profile-vacation",
    path: "/profile/vacation",
    meta: vacationlHmMdpQG6FMeta || {},
    component: () => import("/home/dashboard/app:client/pages/profile/vacation.vue")
  },
  {
    name: "profile-wishlist",
    path: "/profile/wishlist",
    meta: wishlistTUqcOjXEv2Meta || {},
    component: () => import("/home/dashboard/app:client/pages/profile/wishlist.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerA91WXhwh71Meta || {},
    component: () => import("/home/dashboard/app:client/pages/register.vue")
  },
  {
    name: "reset-password-code",
    path: "/reset-password/:code()",
    meta: _91code_93zVzhqm3qXsMeta || {},
    component: () => import("/home/dashboard/app:client/pages/reset-password/[code].vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: indexLg0qDemAe1Meta || {},
    component: () => import("/home/dashboard/app:client/pages/reset-password/index.vue")
  },
  {
    name: "xadmin",
    path: "/xadmin",
    component: () => import("/home/dashboard/app:admin/pages/xadmin/index.vue")
  },
  {
    name: "app_inventory-index",
    path: "/admin/inventory/",
    meta: { ...(index1de6ZtaWs0Meta || {}), ...{"app":"app_inventory"} },
    component: () => import("/home/modules/app_inventory/views/pages/index.vue")
  },
  {
    name: "app_inventory-brands",
    path: "/admin/inventory/brands",
    meta: { ...(index9PeNoekkxEMeta || {}), ...{"app":"app_inventory"} },
    component: () => import("/home/modules/app_inventory/views/pages/brands/index.vue")
  },
  {
    name: "app_inventory-categories",
    path: "/admin/inventory/categories",
    meta: { ...(indexgdjpPvXCg8Meta || {}), ...{"app":"app_inventory"} },
    component: () => import("/home/modules/app_inventory/views/pages/categories/index.vue")
  },
  {
    name: "app_inventory-courier",
    path: "/admin/inventory/courier",
    meta: { ...(indexI6ZeQusNf4Meta || {}), ...{"app":"app_inventory"} },
    component: () => import("/home/modules/app_inventory/views/pages/courier/index.vue")
  },
  {
    name: "app_inventory-export",
    path: "/admin/inventory/export",
    meta: { ...(indexORVEeyKCbSMeta || {}), ...{"app":"app_inventory"} },
    component: () => import("/home/modules/app_inventory/views/pages/export/index.vue")
  },
  {
    name: "app_inventory-import",
    path: "/admin/inventory/import",
    meta: { ...(indexZXzeo5Ce8lMeta || {}), ...{"app":"app_inventory"} },
    component: () => import("/home/modules/app_inventory/views/pages/import/index.vue")
  },
  {
    name: "app_inventory-products",
    path: "/admin/inventory/products",
    meta: { ...(index8HSjOqF7rRMeta || {}), ...{"app":"app_inventory"} },
    component: () => import("/home/modules/app_inventory/views/pages/products/index.vue")
  },
  {
    name: "app_inventory-stocks",
    path: "/admin/inventory/stocks",
    meta: { ...(indextGqt2yeEvIMeta || {}), ...{"app":"app_inventory"} },
    component: () => import("/home/modules/app_inventory/views/pages/stocks/index.vue")
  },
  {
    name: "app_inventory-warehouse",
    path: "/admin/inventory/warehouse",
    meta: { ...(indexMGr7mMc4f0Meta || {}), ...{"app":"app_inventory"} },
    component: () => import("/home/modules/app_inventory/views/pages/warehouse/index.vue")
  },
  {
    name: "app_inventory-stocks-opname",
    path: "/admin/inventory/stocks/opname",
    meta: { ...(opnameaKYYyz735DMeta || {}), ...{"app":"app_inventory"} },
    component: () => import("/home/modules/app_inventory/views/pages/stocks/opname.vue")
  },
  {
    name: "app_inventory-courier-id",
    path: "/admin/inventory/courier/:id",
    meta: { ...(_91id_93N3TznSNJUBMeta || {}), ...{"app":"app_inventory"} },
    component: () => import("/home/modules/app_inventory/views/pages/courier/[id].vue")
  },
  {
    name: "app_inventory-products-id",
    path: "/admin/inventory/products/:id",
    meta: { ...(_91id_93E3HIhROWQxMeta || {}), ...{"app":"app_inventory"} },
    component: () => import("/home/modules/app_inventory/views/pages/products/[id].vue")
  },
  {
    name: "app_inventory-warehouse-id",
    path: "/admin/inventory/warehouse/:id",
    meta: { ...(_91id_93yJGHzrRPs1Meta || {}), ...{"app":"app_inventory"} },
    component: () => import("/home/modules/app_inventory/views/pages/warehouse/[id].vue")
  },
  {
    name: "app_base-index",
    path: "/dropship/",
    meta: { ...(indexJaGsZ5T0vqMeta || {}), ...{"app":"app_base"} },
    component: () => import("/home/modules/app_base/views/pages/index.vue")
  },
  {
    name: "app_base-new-order",
    path: "/dropship/new-order",
    meta: { ...(new_45orderp6F2nLMc4NMeta || {}), ...{"app":"app_base"} },
    component: () => import("/home/modules/app_base/views/pages/new-order.vue")
  },
  {
    name: "app_base-orders",
    path: "/dropship/orders",
    meta: { ...(indexKsrLHtOQCcMeta || {}), ...{"app":"app_base"} },
    component: () => import("/home/modules/app_base/views/pages/orders/index.vue")
  },
  {
    name: "app_base-products",
    path: "/dropship/products",
    meta: { ...(index5AgPODb0ruMeta || {}), ...{"app":"app_base"} },
    component: () => import("/home/modules/app_base/views/pages/products/index.vue")
  },
  {
    name: "app_base-toko",
    path: "/dropship/toko",
    meta: { ...(indexTC44NtdQ26Meta || {}), ...{"app":"app_base"} },
    component: () => import("/home/modules/app_base/views/pages/toko/index.vue")
  },
  {
    name: "app_salesorder-index",
    path: "/admin/transaksi/",
    meta: { ...(indexrsDiXOlSaSMeta || {}), ...{"app":"app_salesorder"} },
    component: () => import("/home/modules/app_salesorder/views/pages/index.vue")
  },
  {
    name: "app_admin-index",
    path: "/admin/",
    meta: { ...(indexnfBH8SEeCCMeta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/index.vue")
  },
  {
    name: "app_admin-forgot-password",
    path: "/admin/forgot-password",
    meta: { ...(forgot_45password8OHDiWyndFMeta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/forgot-password.vue")
  },
  {
    name: "app_admin-login",
    path: "/admin/login",
    meta: { ...(loginEwuG0DtJmOMeta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/login.vue")
  },
  {
    name: "app_admin-logout",
    path: "/admin/logout",
    meta: { ...(logoutOfOCnJALDpMeta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/logout.vue")
  },
  {
    name: "app_admin-register",
    path: "/admin/register",
    meta: { ...(registeryUuapWuC6IMeta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/register.vue")
  },
  {
    name: "app_admin-administrator",
    path: "/admin/administrator",
    meta: { ...(indexLsUTFpyoGsMeta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/administrator/index.vue")
  },
  {
    name: "app_admin-privileges",
    path: "/admin/privileges",
    meta: { ...(indexSEFdrVm3abMeta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/privileges/index.vue")
  },
  {
    name: "app_admin-profile",
    path: "/admin/profile",
    meta: { ...(indexe5PAMPV2Z7Meta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/profile/index.vue")
  },
  {
    name: "app_admin-transaksi",
    path: "/admin/transaksi",
    meta: { ...(indexm4oqvJZm9pMeta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/transaksi/index.vue")
  },
  {
    name: "app_admin-users",
    path: "/admin/users",
    meta: { ...(indexyiE1mRdCJ8Meta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/users/index.vue")
  },
  {
    name: "app_admin-administrator-logs",
    path: "/admin/administrator/logs",
    meta: { ...(indexftw5ez7SVGMeta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/administrator/logs/index.vue")
  },
  {
    name: "app_admin-administrator-privileges",
    path: "/admin/administrator/privileges",
    meta: { ...(indexs6fAzG50uPMeta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/administrator/privileges/index.vue")
  },
  {
    name: "app_admin-administrator-services",
    path: "/admin/administrator/services",
    meta: { ...(indexBzbH6UgcAYMeta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/administrator/services/index.vue")
  },
  {
    name: "app_admin-administrator-users",
    path: "/admin/administrator/users",
    meta: { ...(indexW6zKQ9SHY9Meta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/administrator/users/index.vue")
  },
  {
    name: "app_admin-users-activities",
    path: "/admin/users/activities",
    meta: { ...(indexnbf5aowkuuMeta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/users/activities/index.vue")
  },
  {
    name: "app_admin-users-exports",
    path: "/admin/users/exports",
    meta: { ...(indexJuZ4JjLORYMeta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/users/exports/index.vue")
  },
  {
    name: "app_admin-users-notifications",
    path: "/admin/users/notifications",
    meta: { ...(indexmxwAzsl1B5Meta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/users/notifications/index.vue")
  },
  {
    name: "app_admin-users-roles",
    path: "/admin/users/roles",
    meta: { ...(indexSebUtoQ4l5Meta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/users/roles/index.vue")
  },
  {
    name: "app_admin-administrator-users-activities",
    path: "/admin/administrator/users/activities",
    meta: { ...(indexJIyDsDFcSeMeta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/administrator/users/activities/index.vue")
  },
  {
    name: "app_admin-administrator-users-detail",
    path: "/admin/administrator/users/detail",
    meta: { ...(indexLXWCifcqY5Meta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/administrator/users/detail/index.vue")
  },
  {
    name: "app_admin-administrator-users-exports",
    path: "/admin/administrator/users/exports",
    meta: { ...(indexHM3WG9qKkaMeta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/administrator/users/exports/index.vue")
  },
  {
    name: "app_admin-administrator-users-notifications",
    path: "/admin/administrator/users/notifications",
    meta: { ...(indexde2mPLBimZMeta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/administrator/users/notifications/index.vue")
  },
  {
    name: "app_admin-administrator-users-roles",
    path: "/admin/administrator/users/roles",
    meta: { ...(indexQm22bj0DYeMeta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/administrator/users/roles/index.vue")
  },
  {
    name: "app_admin-administrator-users-detail-id",
    path: "/admin/administrator/users/detail/:id",
    meta: { ...(_91id_93o1OpL6mBvVMeta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/administrator/users/detail/[id].vue")
  },
  {
    name: "app_users-index",
    path: "/admin/users/",
    meta: { ...(index4tLTaKUtPBMeta || {}), ...{"app":"app_users"} },
    component: () => import("/home/modules/app_users/views/pages/index.vue")
  },
  {
    name: "app_assets-index",
    path: "/admin/assets/",
    meta: { ...(indexSoDJaSXOifMeta || {}), ...{"app":"app_assets"} },
    component: () => import("/home/modules/app_assets/views/pages/index.vue")
  }
]