<template lang="pug">
NuxtLayout(
    name="default" 
)
    .mainbody_neutral
        main.mainbox_center.gap-4
            .flex.flex-col.space-y-4
                BaseLogo
            .w-xs 
                .w-full.space-y-4
                    .w-full.space-y-1.w-sm
                        .text-lg.font-bold 404
                        .w-full.text-sm.text-neutral-500 Halaman ini tidak ditemukan.
                        
    BaseColorMode
</template>
<script setup lang="ts">
definePageMeta({
    layout: false,
})

useHead({
    title: 'Register | Dashboard',
})

const toast = useToast()
const router = useRouter()
</script>
