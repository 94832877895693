<script setup>
const colorMode = useColorMode()

const isDark = computed({
    get() {
        return colorMode.value === 'dark'
    },
    set() {
        colorMode.preference = colorMode.value === 'dark' ? 'light' : 'dark'
    },
})
</script>

<template lang="pug">
ClientOnly(v-if="!colorMode?.forced")

    UButton.absolute.right-3.z-10.bottom-3(
        :icon="isDark ? 'i-lucide-moon' : 'i-lucide-sun'"
        color="neutral"
        variant="ghost"
        @click="isDark = !isDark"
    )
    template(#fallback)
        div.size-8
</template>
