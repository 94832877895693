<template lang="pug">
.flex.flex-1.justify-center.px-2(v-if="sdbrStore.sidebarMob")
    UVerticalNavigation.w-full(:links="links" :ui="ui")

.w-full(v-else)
    .flex.flex-1.justify-center.px-2(v-if="sdbrStore.sidebarSm")
        UVerticalNavigation.w-full(:links="links" :ui="ui")

    .flex-col.w-full.flex-1.justify-center.space-y-4(v-else)
        //- pre {{sdbrStore.sidebarMob}}
        .w-full.flex.flex-col.justify-center.gap-5.flex-1(
            v-for="(m, index) in links"
            :key="`m-${index}`"
        )
            .justify-center.flex.flex.flex-col(v-for="(c, index) in m" :key="`smx-${index}`")
                .flex.justify-center
                    NuxtLink.flex.flex-1.justify-between.flex-col.w-full(:to="c.to")
                        .flex.justify-center
                            UButton.text-neutral-200(
                                class="dark:text-neutral-200 hover:bg-neutral-700 hover:text-neutral-400"
                                :icon="c.icon"
                                color="white"
                                variant="link"
                            )
                        .flex.justify-center.px-2
                            .w-full.text-center.text-xs {{ c.label }}
</template>
<script setup lang="ts">
const sdbrStore = useCTStore()
const links = [
    [
        {
            icon: 'i-heroicons-home',
            to: '/',
            label: 'Dashboard',
        },
        {
            icon: 'i-heroicons-plus-circle',
            to: '/dropship/new-order',
            label: 'New Order',
        },
        {
            icon: 'material-symbols-full-stacked-bar-chart',
            to: '/dropship/orders',
            label: 'Transaksi',
        },
        {
            icon: 'i-heroicons-shopping-bag',
            to: '/dropship/products',
            label: 'Produk',
        },
        {
            icon: 'i-heroicons-user',
            to: '/profile',
            label: 'Accounts',
        },


    ],
    // [
    //     {
    //         icon: 'i-material-symbols-settings-outline',
    //         to: '/learning',
    //         label: 'System',
    //     },
    //     // {
    //     //     icon: 'i-heroicons-question-mark-circle',
    //     //     to: '/test_data',
    //     //     label: 'Ask Question',
    //     // },
    // ],
]
const ui = ref({
    wrapper: 'relative',
    base: 'group relative flex items-center gap-1.5 focus:outline-none focus-visible:outline-none dark:focus-visible:outline-none focus-visible:before:ring-inset focus-visible:before:ring-1 focus-visible:before:ring-primary-500 dark:focus-visible:before:ring-primary-400 before:absolute before:inset-px before:rounded-md disabled:cursor-not-allowed disabled:opacity-75',
    ring: 'focus-visible:ring-inset focus-visible:ring-2 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400',
    padding: 'px-2.5 py-2.5',
    width: 'w-full',
    rounded: 'rounded-md',
    font: 'font-medium',
    size: 'text-sm',
    active: 'before:bg-gray-700 text-gray-100 hover:text-gray-200 dark:text-white before:bg-gray-800 hover:before:bg-gray-700 dark:before:bg-gray-800',
    inactive:
        'text-gray-300 dark:text-gray-300 hover:text-gray-100 dark:hover:text-white hover:before:bg-gray-700 dark:hover:before:bg-gray-800/50',
    label: 'truncate relative',
    icon: {
        base: 'mr-2 flex-shrink-0 w-5 h-5 relative',
        active: 'text-gray-400 dark:text-gray-200',
        inactive:
            'text-gray-400 dark:text-gray-200 group-hover:text-gray-200 dark:group-hover:text-gray-200',
    },
    avatar: {
        base: 'flex-shrink-0',
        size: '2xs',
    },
    badge: {
        base: 'flex-shrink-0 ml-auto relative rounded',
        color: 'gray',
        variant: 'solid',
        size: 'xs',
    },
    divider: {
        wrapper: {
            base: 'p-2',
        },
    },
})
</script>
