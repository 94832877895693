<template lang="pug">
NuxtLayout
    Html(translate='no')
    NuxtLoadingIndicator
    UApp
        NuxtPage
</template>
<script setup lang="ts">
//provideHeadlessUseId(() => useId());
useHead({
    // script: [{ src: 'https://cdn.tailwindcss.com', defer: true }],
})
</script>
