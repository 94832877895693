<template lang="pug">
.flex.flex-1.justify-between.gap-2(
    class="py-0.5"
)
    .flex.flex-1.justify-center
        UButton(variant="ghost" color="gray" to="/")
            .flex.flex-1.flex-col.justify-center
                .flex.justify-center.text-center.flex-1
                    UIcon.text-gray-400.w-6.h-6(
                        name="i-solar-home-2-linear"
                    )
                .flex.text-xs.text-center.flex-1.text-neutral-400
                    | Home
    .flex.flex-1.justify-center
        UButton(variant="ghost" color="gray" to="/dropship/orders")
            .flex.flex-1.flex-col.justify-center
                .flex.justify-center.text-center.flex-1
                    UIcon.text-gray-400.w-6.h-6(
                        name="i-solar-bill-list-linear"
                    )
                .flex.text-xs.text-center.flex-1.text-neutral-400
                    | Orders
    .flex.relative.flex-1.items-center.justify-center
        .fixed.bottom-6
            UPopover(mode="hover")
                UButton.w-12.h-12(
                    :ui="bui"
                    variant="solid"
                    color="red"
                    to="/dropship/new-order"
                )
                    .flex.flex-1.flex-col.justify-center
                        .flex.justify-center.text-center.flex-1
                            UIcon.text-white.w-6.h-6(
                                name="i-material-symbols-add-rounded"
                            )
                template(#panel)
                    .w-full.p-2.text-sm Create Order
    .flex.flex-1.justify-center
        UButton(variant="ghost" color="gray" to="/dropship/products")
            .flex.flex-1.flex-col.justify-center
                .flex.justify-center.text-center.flex-1
                    UIcon.text-gray-400.w-6.h-6(
                        name="i-solar-cart-4-linear"
                    )
                .flex.text-xs.text-center.flex-1.text-neutral-400
                    | Products
    .flex.flex-1.justify-center
        UButton(variant="ghost" color="gray" to="/profile")
            .flex.flex-1.flex-col.justify-center
                .flex.justify-center.text-center.flex-1
                    UIcon.text-gray-400.w-6.h-6(
                        name="i-solar-user-broken"
                    )
                .flex.text-xs.text-center.flex-1.text-neutral-400
                    | Account
</template>
<script setup lang="ts">
const bui = {
    rounded: 'rounded-full',
    color: { red: { solid: 'bg-[#cc0000] ring-4 ring-white dark:ring-2 dark:ring-neutral-700/80' } },
}
</script>
